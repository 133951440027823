.InfoPage {
  position: absolute;
  background-color: var(--color-white-forward);
  right: -321px;
  height: 100%;
  width: 320px;
  padding: 3rem 1rem;
  box-sizing: border-box;
  transition: right 250ms ease;
  font-size: 14px;
  font-weight: 400;
  z-index: var(--z-layer-infodrawer);
  color: var(--emphasis-high);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  &--isOpen {
    right: 0;
  }

  &__title {
    font-weight: 500;
    font-size: 15px;
  }
}
:global {
  .ql-align-center {
    text-align: center;
  }
  .ql-align-left {
    text-align: left;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-size-small {
    font-size: 0.75em;
  }

  .ql-size-large {
    font-size: 1.5em;
  }
  .ql-size-huge {
    font-size: 2.5em;
  }
}

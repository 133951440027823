.PreviewPage {
  &__back {
    position: absolute;
    top: 2px;
    left: 2px;
    border: 1px solid var(--color-font);
    background: white;
    height: 1.8rem;
    z-index: 1000;

    &:hover:not(&--flat) {
      background-color: var(--color-white);
    }
  }
}
